<template lang="pug">

q-header.bg-grey-2.q-px-lg(bordered)
  .q-py-lg.text-subtitle1.row.items-center
    span.q-mr-xs
      q-icon(name="cell_tower" size="md" color="black")
    span.text-black.text-bold CHeKT Ham

  q-separator

  .q-py-lg
    .text-h4.text-black P2P connection
    .q-py-xs
    .text-body1.text-grey-7 Testing tools for P2P connection for CHeKT developers

</template>

<style>
</style>

<script>
export default {
}
</script>
